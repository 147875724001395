import {
  AUTH_TOKEN,
  removeItemInLocalStorage,
  getItemInLocalStorage,
} from "@/service/localStorage";
import { signOut } from "next-auth/react";
import { CustomApiError, UnauthenticationError } from "./exception";

const defaultBaseUrl = process.env.NEXT_PUBLIC_BASE_URL;
const webBaseUrl = process.env.NEXTAUTH_URL;

export async function fetchByClientSide(
  url: string,
  options: RequestInit = {},
  requiredAuth = true,
  useWebBaseUrl = false
) {
  try {
    const token = getItemInLocalStorage(AUTH_TOKEN);
    if (!token && requiredAuth) {
      throw new UnauthenticationError();
    }

    const baseUrl = useWebBaseUrl ? webBaseUrl : defaultBaseUrl;

    const res = await fetch(`${baseUrl}${url}`, {
      ...options,
      credentials: "include",
      headers: {
        Authorization: requiredAuth ? `Bearer ${token}` : "",
        ...options.headers,
      },
    });
    const data = await res.json();
    if (!res.ok) {
      throw new CustomApiError(data.error ?? data.detail, res.status);
    }
    if (url === "/api/auth/google") {
      return {
        ...data,
        isNewAccount: res.status === 201,
      };
    }
    return data;
  } catch (e) {
    if (e instanceof UnauthenticationError) {
      removeItemInLocalStorage(AUTH_TOKEN);
      signOut({ callbackUrl: "/auth" });
    }else{
      throw e;
    }
  }
}
