// components/ResourceErrorHandler.tsx
"use client"; // 이 컴포넌트만 클라이언트 컴포넌트로 설정

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';

import { redirect } from 'next/navigation';
import { AUTH_TOKEN, CHAT_GUIDE_MODAL_SHOWED_BEFORE, FEEDBACK_DO_NOT_OPEN_AGAIN, removeItemInLocalStorage } from '@/service/localStorage';

import useErrorModal from '@/hooks/useErrorModal';
import { useMutation } from '@tanstack/react-query';
import { signOutAccount } from "@/api/api";

export default function ResourceErrorHandler() {
  const router = useRouter();
  const { data: session, status } = useSession();
  const { showErrorModal } = useErrorModal();

  const { mutate: handleSignOut } = useMutation({
    mutationFn: signOutAccount,
    onSuccess: () => {
      removeItemInLocalStorage(AUTH_TOKEN);
      removeItemInLocalStorage(FEEDBACK_DO_NOT_OPEN_AGAIN);
      removeItemInLocalStorage(CHAT_GUIDE_MODAL_SHOWED_BEFORE);
      router.push("/auth?isError=true");
    },
    onError: () => {
      showErrorModal();
    }
  });

    // JWT 토큰 디코딩 함수
  function parseJwt(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Invalid JWT Token:", e);
      return null;
    }
  }

  // 토큰 만료 여부 확인 함수
  function isTokenExpired(token: string) {
    const decoded = parseJwt(token);
    if (!decoded || !decoded.exp) {
      return true;  // 토큰이 유효하지 않거나 exp가 없는 경우 만료된 것으로 처리
    }

    const now = Math.floor(Date.now() / 1000);  // 현재 시간 (초 단위)
    return decoded.exp < now;  // 만료 시간이 현재 시간보다 작으면 만료된 것
  }

  useEffect(() => {
    const checkResourceLoad = () => {
      const resources = window.performance.getEntriesByType("resource");
            const token = session?.user?.token;

            if (status !== "loading" && token && isTokenExpired(token)) {
                signOut();
                handleSignOut();
                redirect("/auth");
            }
      };

    checkResourceLoad();
  }, [status, session, handleSignOut]);

  return null;
}