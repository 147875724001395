import { ChatSession } from "@/hooks/useChatSession";
import { Topic } from "@/types/topic";
import { PaymentStatus } from "@/hooks/usePaymentStatus";
import { PaddleSubscriptionStatus } from "@/hooks/useSubscriptionStatus";
import { fetchByClientSide } from "./fetchByClientSide";
import { FeedbackOption } from "@/hooks/useFeedbackOptions";
import { UnauthenticationError } from './exception'; // 경로를 실제 파일 위치에 맞게 조정하세요.

export async function getMe() {
  try {
    const data = await fetchByClientSide("/api/users/me");
    return data;
  } catch (e) {
    if (e instanceof UnauthenticationError) {
      await signOutAccount();
      return null; // 또는 적절한 반환 값 설정
    }
    throw e; // 다른 에러는 그대로 throw
  }
}

export async function getMePoints() {
  const data = await fetchByClientSide("/api/users/points");
  data.next_day_at = new Date();
  data.next_day_at.setTime(
    data.next_day_at.getTime() + data.seconds_until_next_day * 1000,
  );
  return data;
}

export async function getRecentSessions(): Promise<ChatSession[]> {
  const data = await fetchByClientSide("/api/sessions/recent");
  return data.reverse();
}

export async function postNewTotic(topic: Topic) {
  const { segment_code, code } = topic;
  const data = await fetchByClientSide(
    `/api/sessions/${segment_code}/${code}`,
    {
      method: "POST",
    },
  );
  return data;
}

export async function postChatMessage({
  message,
  promptId,
}: {
  message: string;
  promptId?: number;
}) {
  const formData = new FormData();
  formData.append("content", message);
  if (promptId) {
    formData.append("quick_prompt", promptId.toString());
  }
  const data = await fetchByClientSide("/api/sessions/recent/messages", {
    method: "POST",
    body: formData,
  });
  return data;
}

export async function getPrompts() {
  const data = await fetchByClientSide("/api/prompts");
  return data;
}

export async function deleteAccount() {
  const data = await fetchByClientSide("/api/users/me", { method: "DELETE" });
  return data;
}

export async function signOutAccount() {
  const data = await fetchByClientSide("/api/auth/logout", { method: "POST" });
  return data;
}

export async function postGoogleAuth({ idToken }: { idToken: string }) {
  const data = await fetchByClientSide(
    "/api/auth/google",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_token: idToken,
        access_token: "",
        platform: "web",
      }),
    },
    false,
  );
  return data;
}

export async function getInvitationCode() {
  const data = await fetchByClientSide("/api/users/me/invitation");
  return data;
}

export async function getPaddleSubscriptionStatus(): Promise<PaddleSubscriptionStatus> {
  const data = await fetchByClientSide("/api/payments/paddle/status");
  return data;
}

export async function getPaymentStatus(): Promise<PaymentStatus> {
  const data = await fetchByClientSide("/api/payments/status");
  return data;
}

export async function deletePaddleCancel(): Promise<PaddleSubscriptionStatus> {
  const data = await fetchByClientSide("/api/payments/paddle/cancel", {
    method: "DELETE",
  });
  return data;
}

export async function postTts({
  message,
  voiceId,
}: {
  message: string;
  voiceId: string;
}) {
  const data = await fetch("/api/chat/tts", {
    method: "POST",
    body: JSON.stringify({
      message,
      voiceId,
    }),
    credentials: "omit",
  });
  return data;
}

export async function getFeedbackOptions(): Promise<FeedbackOption[]> {
  const data = await fetchByClientSide("/api/feedback/options");
  return data;
}

export interface UserFeedback {
  option_id: number;
  comment?: string;
}

export async function postFeedback(items: UserFeedback[]) {
  const data = await fetchByClientSide("/api/feedback/submit", {
    method: "POST",
    body: JSON.stringify({
      items,
    }),
  });
  return data;
}

export async function createAgent(channelUrl: string) {
  const data = await fetchByClientSide("/api/channel/channel", {
    method: "POST",
    body:JSON.stringify({ channel_url: channelUrl }),
  });
  return data;
}

export async function getAgentGroup(page: number = 1, size: number = 12) {
  const data = await fetchByClientSide(`/api/channel/agents?page=${page}&size=${size}`);
  return data;
}

export async function getProgressAgents(page: number = 1, size: number = 100) {
  const data = await fetchByClientSide(`/api/channel/myagent/progress?page=${page}&size=${size}`, {});
  return await data;
}

export async function getMyAgents(page: number = 1, size: number = 100) {
  const data = await fetchByClientSide(`/api/channel/myagent/complete?page=${page}&size=${size}`, {});
  return await data;
}

export async function searchResult(query: string) {
  const data = await fetchByClientSide("/api/channel/function/search", {
    method: "POST",
    body:JSON.stringify({ query: query }),
  });
  return await data;
}

export async function createMyAgent(channelUrl: string) {
  const data = await fetchByClientSide(`/api/channel/create/myagent`, {
    method: "POST",
    body: JSON.stringify({ channel_url: channelUrl })
  });
  return data;
}

